import type { BoxProps } from '@mui/material';
import { Box } from '@mui/material';
import { useMemo } from 'react';
import { removeExternalLinks, sanitize } from '../util/sanitize';

interface Props extends Omit<BoxProps, 'dangerouslySetInnerHTML'> {
  html: string | undefined | null;
  needSanitize?: boolean;
  removeLink?: boolean;
}

function fixHtml(html: string | undefined | null, needSanitize: boolean, removeLink: boolean) {
  if (!html) return '';
  let result = html;
  if (needSanitize) {
    result = sanitize(result);
  }
  if (removeLink) {
    result = removeExternalLinks(result) ?? '';
  }
  return result;
}

export function HtmlView(props: Props) {
  const { html, needSanitize = false, removeLink = false, ...rest } = props;
  const fixedHtml = useMemo(
    () => fixHtml(html, needSanitize, removeLink),
    [html, needSanitize, removeLink],
  );
  return <Box {...rest} dangerouslySetInnerHTML={{ __html: fixedHtml }} />;
}
