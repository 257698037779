'use client';

import { useCallback, useEffect, useState } from 'react';
import {
  UserProfileViewDialog,
  type UserProfileViewDialogProps,
} from '../../dialogs/UserProfileViewDialog';
import { UserProfileDialogOpenEvent } from './UserProfileDialogOpenEvent';

type DialogParams = {
  id: 'UserProfileViewDialog';
  props: UserProfileViewDialogProps;
};

export function UserProfileDialogOpenEventHandler() {
  const [dialogParams, setDialogParams] = useState<DialogParams | undefined>(undefined);

  const openUserProfileView = useCallback((accountId: number) => {
    setDialogParams({
      id: 'UserProfileViewDialog',
      props: {
        open: true,
        onClose: () => {
          setDialogParams(undefined);
        },
        accountId,
      },
    });
  }, []);

  useEffect(() => {
    // accountId=0이면 무시한다
    UserProfileDialogOpenEvent.on((accountId) => {
      if (!accountId) return;
      openUserProfileView(accountId);
    });
  }, [openUserProfileView]);

  if (!dialogParams) return null;

  return (
    <>
      {dialogParams.id === 'UserProfileViewDialog' && (
        <UserProfileViewDialog {...dialogParams.props} />
      )}
    </>
  );
}
