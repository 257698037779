import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { type SxProps } from '@mui/material';
import clsx from 'clsx';
import { CountWithSvgIcon } from './CountWithSvgIcon';

interface Props {
  sx?: SxProps;
  className?: string;
  count: number | string;
  size?: 'small' | 'medium' | 'large';
}

/**
 * 숫자와 Eye icon
 */
export function CountWithEyeIcon(props: Props) {
  const { className, ...restProps } = props;
  return (
    <CountWithSvgIcon
      {...restProps}
      icon={<VisibilityOutlinedIcon />}
      className={clsx('CountWithEyeIcon-root', className)}
    />
  );
}
