import type { SxProps } from '@mui/material';
import { Box, Container, NoSsr } from '@mui/material';
import { flatSx } from '@ocodelib/ui-common';
import clsx from 'clsx';
import React from 'react';
import { AspectRatio } from 'react-aspect-ratio';
import 'react-aspect-ratio/aspect-ratio.css';
import type ReactPlayer from 'react-player';

const ReactPlayerComponent = React.lazy(() => import('react-player'));

interface Props {
  sx?: SxProps;
  className?: string;
  title?: string;
  caption?: string;
  videoUrl: string;
  transparentBg?: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

export const VideoPlayerWrapper = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { sx, className, maxWidth = 'sm', title, caption, videoUrl, transparentBg } = props;

  const handleOnReady = (player: ReactPlayer) => {
    //
  };

  return (
    <Box
      className={clsx('VideoPlayerWrapper-root', className)}
      ref={ref}
      sx={flatSx(
        {
          py: 3,
          px: 2,
          background: transparentBg ? 'transparent' : '#f0f0f0',
          borderRadius: 2,
          border: transparentBg ? 'none' : '1px solid #eee',
          my: 1,
          width: '100%',
        },
        sx,
      )}
    >
      <Container
        maxWidth={maxWidth}
        disableGutters
        sx={{
          width: '100%',
        }}
      >
        {title && (
          <Box
            sx={{
              mb: 3,
              fontSize: '1.2rem',
              textAlign: 'center',
              color: '#191919',
              fontWeight: 500,
              lineHeight: '1.4rem',
            }}
          >
            {title}
          </Box>
        )}

        <Box>
          <NoSsr>
            <AspectRatio ratio="16/9" style={{ maxWidth: 1920 }}>
              <React.Suspense fallback={<div>Loading...</div>}>
                <ReactPlayerComponent
                  width="100%"
                  height="100%"
                  url={videoUrl}
                  controls
                  onReady={handleOnReady}
                />
              </React.Suspense>
            </AspectRatio>
          </NoSsr>
        </Box>
        {caption && (
          <Box
            className="VideoPlayerWrapper-caption"
            sx={{
              mt: 1,
              color: '#666',
              textAlign: 'center',
              fontSize: '0.8rem',
              fontWeight: 500,
              lineHeight: '1.2rem',
            }}
          >
            {caption}
          </Box>
        )}
      </Container>
    </Box>
  );
});

VideoPlayerWrapper.displayName = 'VideoPlayerWrapper';
