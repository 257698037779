import { Box, Chip, type SxProps } from '@mui/material';
import { BlkBreeds, ProjectBreeds, TextBreeds, type ProjectBreedKey } from '@ocode/domain';
import { flatSx } from '@ocodelib/ui-common';
import clsx from 'clsx';
import { useMemo } from 'react';

interface Props {
  sx?: SxProps;
  className?: string;

  /**
   * 현재 breed
   */
  currentBreed?: ProjectBreedKey;

  /**
   * '전체'를 표시하려면 undefinedLabel에 '전체'로 설정하세요.
   */
  undefinedLabel?: string;

  /**
   * breed 목록, default='all'
   * all: 전체 breeds
   * blk: blox용 breeds
   * text: text용 breeds
   */
  breed?: 'all' | 'blk' | 'text' | ProjectBreedKey[];

  /**
   * 변경 핸들러
   */
  onChange?: (breed?: ProjectBreedKey) => void;
}

const breedChipTitle: { [key: string]: string } = {
  js: '자바스크립트',
  py: '파이썬',
  p5: 'P5.js',
  blox: '블록코딩',
};

function getBreedList(
  breedList: undefined | 'all' | 'blk' | 'text' | ProjectBreedKey[] = 'all',
): ProjectBreedKey[] {
  if (breedList === 'all') {
    return Object.keys(ProjectBreeds) as ProjectBreedKey[];
  } else if (breedList === 'blk') {
    return Object.keys(BlkBreeds) as ProjectBreedKey[];
  } else if (breedList === 'text') {
    return Object.keys(TextBreeds) as ProjectBreedKey[];
  }
  return breedList;
}

export function BreedChips(props: Props) {
  const { sx, className, currentBreed, undefinedLabel, onChange } = props;
  const breedList = useMemo(() => getBreedList(props.breed), [props.breed]);

  const handleChange = (breed?: ProjectBreedKey) => () => {
    onChange?.(breed);
  };

  const clickable = !!onChange;

  return (
    <Box
      className={clsx('BreedChips-root', className)}
      sx={flatSx(
        {
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          ml: -0.5,
          mt: -0.5,
          '& .MuiChip-root': {
            ml: 1,
            mt: 0.5,
          },
          '& .MuiButtonBase-root': {
            border: 'none',
            px: 1,
            py: 2,
          },
        },
        sx,
      )}
    >
      {undefinedLabel && (
        <Chip
          className="BreedChips-chip"
          label={undefinedLabel}
          size="small"
          color="primary"
          clickable={clickable}
          onClick={clickable ? handleChange(undefined) : undefined}
          sx={{
            backgroundColor: !currentBreed ? '#00ACFF' : '#F3F5F8',
            color: !currentBreed ? '#fff' : '#4E5052',
            ':hover': {
              backgroundColor: !currentBreed ? '#00ACFF' : '#F3F5F8',
            },
          }}
        />
      )}

      {breedList.map((breed) => (
        <Chip
          className="BreedChips-chip"
          key={breed}
          label={breedChipTitle[breed] ?? breed}
          size="small"
          color="primary"
          component="a"
          clickable={clickable}
          onClick={clickable ? handleChange(breed as any) : undefined}
          sx={{
            backgroundColor: currentBreed === breed ? '#00ACFF' : '#F3F5F8',
            color: currentBreed === breed ? '#fff' : '#4E5052',
            fontSize: '0.875rem',
            ':hover': {
              backgroundColor: currentBreed === breed ? '#00ACFF' : '#F3F5F8',
            },
            '&.MuiButtonBase-root > span': {
              px: '12px',
            },
          }}
        />
      ))}
    </Box>
  );
}
