'use client';

import EditIcon from '@mui/icons-material/Edit';
import { Alert, Box, IconButton, styled, SxProps, Tooltip } from '@mui/material';
import type { CodeProjectOrigin, Gallery } from '@ocode/domain';
import { flatSx } from '@ocodelib/ui-common';
import clsx from 'clsx';
import Link from 'next/link';
import React, { useState } from 'react';
import { HtmlView } from '../../components/HtmlView';
import { VideoPlayerWrapper } from '../../components/VideoPlayerWrapper';
import { GalleryEditDialog, type GalleryEditDialogProps } from '../../dialogs/GalleryEditDialog';
import {
  GallerySourceListDialog,
  type GallerySourceListDialogProps,
} from '../../dialogs/GallerySourceListDialog';
import { useLoginUser } from '../../store/useAuthStore';

interface Props {
  className?: string;
  sx?: SxProps;
  gallery: Gallery;
  forkCount?: number;
  projectOriginList?: CodeProjectOrigin[];
  reloadGallery?: () => void;
}

type EditDialogParams = {
  id: 'GalleryEditDialog';
  props: GalleryEditDialogProps;
};

type SourceListDialogParams = {
  id: 'GallerySourceListDialog';
  props: GallerySourceListDialogProps;
};

export function GalleryIntroView(props: Props) {
  const { sx, className, gallery, projectOriginList = [], forkCount = 0, reloadGallery } = props;
  const [editDialogParams, setEditDialogParams] = useState<EditDialogParams>();
  const [sourceListDialogParams, setSourceListParams] = useState<SourceListDialogParams>();
  const { isAdmin, profile } = useLoginUser();
  const isOwner = gallery.accountId === profile?.accountId;
  const parentProject = projectOriginList[0];

  // 갤러리 편집 다이얼로그
  const openGalleryEditDialog = (galleryId: number) => {
    setEditDialogParams({
      id: 'GalleryEditDialog',
      props: {
        open: true,
        onClose: (saved?: Gallery) => {
          setEditDialogParams(undefined);
          if (saved) {
            reloadGallery?.();
          }
        },
        galleryId,
      },
    });
  };

  const openGallerySourceListDialog = () => {
    setSourceListParams({
      id: 'GallerySourceListDialog',
      props: {
        open: true,
        onClose: () => {
          setSourceListParams(undefined);
        },
        projectOriginList,
        galleryTitle: gallery.title,
      },
    });
  };

  const handleClickParentProject = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    if (!parentProject) return;
    openGallerySourceListDialog();
  };

  const { introContent, content, youtubeUrl } = gallery;

  return (
    <Box className={clsx('GalleryIntroView-root', className)} sx={flatSx(sx)}>
      <Box
        className="GalleryIntroView-topBox"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          pt: 1,
        }}
      >
        {introContent && (
          <Alert
            className="GalleryIntroView-intro"
            icon={false}
            sx={{
              lineHeight: 1.8,
              fontSize: '1rem',
              px: 0,
              letterSpacing: 0.2,
              wordBreak: 'break-all',
              background: '#fff',
              color: '#222',
            }}
          >
            {introContent}
          </Alert>
        )}

        <Box sx={{ ml: 'auto' }}>
          {(isOwner || isAdmin) && (
            <Tooltip title="수정">
              <IconButton
                color="default"
                size="small"
                onClick={() => {
                  openGalleryEditDialog(gallery.galleryId);
                }}
              >
                <EditIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </Box>

      {(forkCount > 0 || parentProject) && (
        <Box sx={{ display: 'flex', mb: 5 }}>
          {parentProject && (
            <TagItemBox>
              출처:
              <Box
                sx={{
                  maxWidth: 110,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                <Link href="#" onClick={handleClickParentProject}>
                  {parentProject.projectName}
                </Link>
              </Box>
            </TagItemBox>
          )}

          {forkCount > 0 && (
            <TagItemBox>
              리메이크한 작품수
              <Link href="#">{forkCount}</Link>
            </TagItemBox>
          )}
        </Box>
      )}

      {youtubeUrl && (
        <Box sx={{ mt: 2 }}>
          <VideoPlayerWrapper transparentBg videoUrl={youtubeUrl} />
        </Box>
      )}

      <Box
        className="GalleryIntroView-content lightbox-parent"
        sx={{
          my: 2,
          px: 2,
          py: 0.5,
          lineHeight: 1.7,
          fontSize: '0.9rem',
          letterSpacing: 0.2,
          wordBreak: 'break-word',
          backgroundColor: '#F3F5F8',
          '& img': {
            maxWidth: '100%',
          },
        }}
      >
        {content && <HtmlView html={content} needSanitize removeLink />}
      </Box>

      {editDialogParams?.id === 'GalleryEditDialog' && (
        <GalleryEditDialog {...editDialogParams.props} />
      )}
      {sourceListDialogParams?.id === 'GallerySourceListDialog' && (
        <GallerySourceListDialog {...sourceListDialogParams.props} />
      )}
    </Box>
  );
}

const TagItemBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginRight: theme.spacing(1),
  padding: theme.spacing(0.2, 2),
  background: '#EEF0F2',
  borderRadius: '20px',
  fontSize: '0.8rem',
  color: '#76787A',
  '& a': {
    color: '#60cbff',
    textDecoration: 'underline',
    marginLeft: '5px',
    fontWeight: 700,
  },
}));
