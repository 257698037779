import { Box, LinearProgress, Typography } from '@mui/material';
import type { UserAccountSimple } from '@ocode/domain';
import { ApiRequestContext } from '@ocodelib/api-common';
import { useCallback, useEffect, useState } from 'react';
import { DEFAULT_PROFILE_IMAGE_URL } from '../../../../config/code-config';
import { useApi } from '../../../../provider/useApi';
import { handleError } from '../../../../util/handle-error';

interface Props {
  accountId: number;
}

export function ProfileContentView(props: Props) {
  const { accountId } = props;
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [userAccount, setUserAccount] = useState<UserAccountSimple>();
  const api = useApi();

  const doLoad = useCallback(
    async (ctx: ApiRequestContext, accountId: number) => {
      setLoading(true);
      try {
        const { profile } = await api.userProfile.profileOther({ ctx, accountId });
        if (ctx.canceled) return;
        setUserAccount(profile);
      } catch (err: any) {
        handleError(err);
      } finally {
        setLoading(false);
      }
    },
    [api],
  );

  useEffect(() => {
    const ctx = ApiRequestContext.of();
    doLoad(ctx, accountId);
    return () => {
      ctx.cancel();
    };
  }, [doLoad, accountId]);

  const { loginId, profileImageUrl } = userAccount || {};
  const userProfileImageUrl = profileImageUrl ?? DEFAULT_PROFILE_IMAGE_URL;

  return (
    <Box
      className="ProfileContentView-root"
      sx={{
        position: 'relative',
      }}
    >
      <Box>
        {userAccount && (
          <Box
            className="ProfileContentView-content"
            sx={{
              position: 'relative',
              py: 2,
              width: 290,
              minHeight: 200,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            {!loading && (
              <>
                <Typography
                  variant="h5"
                  className="ProfileContentView-profileTitle"
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {loginId || '이름없음'}
                </Typography>
                <Box
                  className="ProfileContentView-profileImage"
                  sx={{
                    position: 'relative',
                    width: 150,
                    height: 150,
                    borderRadius: '50%',
                    overflow: 'hidden',
                    display: 'flex',
                    justifyContent: 'center',
                    my: 2,
                    mx: 0,
                    '& img': {
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain',
                    },
                  }}
                >
                  <img src={userProfileImageUrl} alt="" />
                </Box>
              </>
            )}
          </Box>
        )}

        <Typography
          variant={userAccount?.mood ? 'body2' : 'caption'}
          className="ProfileContentView-mood"
          sx={{
            display: 'block',
            width: '100%',
            px: 1.5,
            mb: 1,
            mx: 0,
            textAlign: 'center',
            pb: 1.5,
            fontSize: '0.8rem',
          }}
        >
          {userAccount?.mood || '소개글이 없습니다.'}
        </Typography>

        {(loading || saving) && (
          <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: 30 }}>
            <LinearProgress color="secondary" />
          </div>
        )}
      </Box>
    </Box>
  );
}
