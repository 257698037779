import { Box, LinearProgress, TableContainer, Typography, type SxProps } from '@mui/material';
import { PagerData, type Gallery, type GallerySimple, type ProjectBreedKey } from '@ocode/domain';
import { ApiRequestContext } from '@ocodelib/api-common';
import { useLoadingVisible } from '@ocodelib/react-hooks';
import { flatSx } from '@ocodelib/ui-common';
import { useIsomorphicLayoutEffect } from 'ahooks';
import { useCallback, useEffect, useState } from 'react';
import { DelayMount } from '../../../../components/DelayMount';
import { GalleryListRow } from '../../../../components/GalleryListRow';
import { NoDataView } from '../../../../components/NoDataView';
import { useApi } from '../../../../provider/useApi';
import { handleError } from '../../../../util/handle-error';
import {
  GalleryPreviewDialog,
  type GalleryPreviewDialogProps,
} from '../../../GalleryPreviewDialog';

type DialogParams = {
  id: 'GalleryPreviewDialog';
  props: GalleryPreviewDialogProps;
};

type SearchParams = {
  pageNumber: number;
  rowsPerPage: number;
  accountId: number;
};

const DEFAULT_SEARCH_PARAMS = {
  pageNumber: 0,
  rowsPerPage: 30,
  accountId: 0,
};

interface Props {
  sx?: SxProps;
  breed: ProjectBreedKey[];
  accountId: number;
  onClickGallery?: (item: GallerySimple) => void;
  onClose: () => void;
}

export default function ProfileListView(props: Props) {
  const { sx, accountId, onClickGallery, breed, onClose } = props;
  const [searchParams, setSearchParams] = useState<SearchParams>(() => DEFAULT_SEARCH_PARAMS);
  const [pagerData, setPagerData] = useState<PagerData<Gallery>>();
  const [loading, setLoading] = useState(false);
  const loadingVisible = useLoadingVisible(loading);
  const [dialogParams, setDialogParams] = useState<DialogParams>();
  const api = useApi();
  const [refreshToken, setRefreshToken] = useState(0);

  useIsomorphicLayoutEffect(() => {
    setSearchParams((p) => ({ ...p, accountId }));
  }, [accountId]);

  // 갤러리 업데이트
  const updateGallery = (newGallery: GallerySimple) => {
    const elements = pagerData?.elements;
    if (!elements) return;
    const idx = elements.findIndex((it) => it.galleryId === newGallery.galleryId);
    if (idx < 0) return;
    const cloned: GallerySimple = {
      ...elements[idx]!,
      seenCount: newGallery.seenCount,
      favorCount: newGallery.favorCount,
      chimCount: newGallery.chimCount,
      isFavor: newGallery.isFavor,
      isChim: newGallery.isChim,
    };

    const newPagerData = {
      ...pagerData,
      elements: elements.map((it) => (it.galleryId === cloned.galleryId ? cloned : it)),
    };
    setPagerData(newPagerData);
  };

  const doLoadGalleryList = useCallback(
    async (ctx: ApiRequestContext, params: SearchParams, breed: ProjectBreedKey[]) => {
      setLoading(true);
      try {
        const { pagerData } = await api.gallery.search({ ctx, ...params, breed });
        if (ctx.canceled) return;
        setPagerData(pagerData);
      } catch (err) {
        handleError(err);
      } finally {
        setLoading(false);
      }
    },
    [api],
  );
  useEffect(() => {
    if (searchParams.accountId === 0) return;
    const ctx = ApiRequestContext.of();
    doLoadGalleryList(ctx, searchParams, breed);
    return () => {
      ctx.cancel();
    };
  }, [doLoadGalleryList, searchParams, breed]);

  const handleClickRow = (gallery: GallerySimple) => () => {
    const galleryId = gallery.galleryId;
    if (onClickGallery) {
      onClickGallery(gallery);
      return;
    }
    setDialogParams({
      id: 'GalleryPreviewDialog',
      props: {
        galleryId,
        open: true,
        // onClose: (changedGallery?: Gallery) => {
        //   setDialogParams(undefined);
        //   if (changedGallery) {
        //     updateGallery(changedGallery);
        //   }
        // },
        onClose: onClose,
        onDeleted: () => {
          setRefreshToken(Date.now());
          setDialogParams(undefined);
        },
      },
    });
  };

  const {
    totalPages = 0,
    pageNumber = 0,
    elements = [],
    offset = 0,
    totalElements = 0,
  } = pagerData ?? {};
  const itemNumMax = totalElements - offset;

  return (
    <Box className="ProfileListView-root" sx={flatSx({}, sx)}>
      <Box
        sx={{
          position: 'relative',
          '& .GalleryListRow-root': {
            border: '1px solid #ddd',
            minWidth: 630,
            '& + .GalleryListRow-root': {
              mt: 2,
            },
          },
        }}
      >
        {totalElements === 0 && (
          <DelayMount>
            <NoDataView title="작품이 없습니다." />
          </DelayMount>
        )}
        {totalElements > 0 && (
          <Typography
            variant="body2"
            sx={{
              pl: 2,
              color: '#888',
            }}
          >
            전체 {totalElements}개
          </Typography>
        )}
        <TableContainer sx={{ mt: 1 }}>
          {elements.map((gal) => (
            <GalleryListRow
              key={gal.galleryId}
              userHidden
              onClick={handleClickRow(gal)}
              gallery={gal}
              sx={{
                mx: 2,
                '& .GalleryListRow-right': {
                  justifyContent: 'flex-end',
                },
              }}
            />
          ))}
        </TableContainer>
      </Box>

      {dialogParams?.id === 'GalleryPreviewDialog' && (
        <GalleryPreviewDialog {...dialogParams.props} />
      )}

      {loadingVisible && (
        <Box sx={{ position: 'absolute', top: 0, left: 0 }}>
          <LinearProgress />
        </Box>
      )}
    </Box>
  );
}
