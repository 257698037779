import type { SxProps } from '@mui/material';
import { asReadonlyBS, Closables, createSetterBS } from '@ocodelib/ex-util/rx';
import type { IframeController } from '@ocodelib/iframe-parent';
import { useObservable } from '@ocodelib/react-hooks';
import { useUnmount } from 'ahooks';
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { BehaviorSubject, fromEvent, map, takeUntil, type Observable } from 'rxjs';
import { IframeWrapperNoSsr } from './IframeWrapperNoSsr';

type IframeQuillApp = ReturnType<typeof createApp>;

export type IframeQuillEditorType = {
  iframeController: IframeController;
  getText: () => Promise<string | null>;
  setText: (text: string) => Promise<void>;
  text$: Observable<string | null>;
  close: () => void;
};

interface Props {
  sx?: SxProps;
  className?: string;
  revision?: string | number | bigint;
  onLoadEditor?: (editor: IframeQuillEditorType | undefined) => void;
}

/**
 * @example

<IframeQuillEditorWrapper
    onLoadEditor={(editor) => {
        if (!editor) return;
        editor.setText('foo');
    }}
/>

 */
export function IframeQuillEditorWrapper(props: Props) {
  const { sx, className, revision } = props;
  const onLoadEditorRef = useRef(props.onLoadEditor);
  onLoadEditorRef.current = props.onLoadEditor;
  const [iframeController, setIframeController] = useState<IframeController | null>(null);
  const [app, setApp] = useState<IframeQuillApp>();
  const editor = useObservable(app?.editor$);

  useUnmount(() => {
    app?.destroy();
    setApp(undefined);
  });

  useEffect(() => {
    onLoadEditorRef.current?.(editor ?? undefined);
  }, [editor]);

  const handleLoadIframe = (iframe: IframeController | null) => {
    setIframeController(iframe);
  };

  useEffect(() => {
    if (!app) return;
    app.setIframeController(iframeController);
    return () => {
      app.setIframeController(null);
    };
  }, [app, iframeController]);

  useEffect(() => {
    const app = createApp();
    app.init();
    setApp(app);
    return () => {
      app.destroy();
      setApp(undefined);
    };
  }, []);

  return (
    <IframeWrapperNoSsr
      key={revision}
      className={clsx('IframeQuillEditorWrapper-root', className)}
      // devUrl="http://localhost:9018"
      urlPath="/sys.iframe/quill/index.html"
      sx={sx}
      idPrefix="quill"
      onLoad={handleLoadIframe}
    />
  );
}

function createApp() {
  const iframeController$ = new BehaviorSubject<IframeController | null>(null);
  const closables = new Closables();

  function init() {
    console.log('IframeQuillEditorWrapper.init()');
  }

  // iframe이 연결되면, 에디터 객체 생성
  const editor$ = iframeController$.pipe(
    map((iframeController) => {
      if (!iframeController) return null;
      return newEditor(iframeController);
    }),
  );

  /**
   * 신규 에디터 생성
   */
  const newEditor = (iframe: IframeController): IframeQuillEditorType => {
    async function setText(text: string) {
      await iframe.runCmd('setEditorText', [text]);
    }

    async function getText(): Promise<string | null> {
      return (await iframe.runCmd('getEditorText')) as string | null;
    }

    // iframe에서 보내는 이벤트
    // type CustomEventMap = {
    //   'text-changed': (text: string | undefined) => void;
    // };

    function close() {
      closables.remove(close);
    }

    closables.add(close);

    const text$ = fromEvent(iframe.events, 'text-changed').pipe(
      takeUntil(closables.closeTrigger$),
    ) as unknown as Observable<string | null>;

    return {
      iframeController: iframe,
      getText,
      setText,
      text$,
      close,
    };
  };

  function destroy() {
    console.log('IframeQuillEditorWrapper.close()');
    closables.close();
  }

  return {
    init,
    destroy,
    iframeController$: asReadonlyBS(iframeController$),
    setIframeController: createSetterBS(iframeController$),
    editor$,
  };
}
