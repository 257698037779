'use client';

import { alpha, styled, Tab, type TabProps } from '@mui/material';
import { red } from '@mui/material/colors';

type CustomTabProps = {
  activeColor?: string;
} & Omit<TabProps, 'disableRipple'>;

export const PageContentTab = styled((props: CustomTabProps) => <Tab disableRipple {...props} />, {
  shouldForwardProp: (prop) => prop !== 'activeColor',
})(({ theme, activeColor = '#000' }) => ({
  color: '#4E5052',
  transition: '0.2s',
  '&.MuiButtonBase-root': {
    padding: 0,
    minWidth: 75,
    // margin: '0 16px',
  },
  // '&.MuiButtonBase-root:first-of-type': {
  //   marginLeft: 0,
  // },
  // '&.MuiButtonBase-root:last-of-type': {
  //   marginRight: 0,
  // },
  '&.Mui-selected': {
    color: '#00ACFF',
    fontWeight: 500,
  },
  [theme.breakpoints.up('xs')]: {
    padding: '0 8px',
    minWidth: 65,
    fontSize: '0.9rem',
    fontWeight: 500,
  },
  [theme.breakpoints.up('sm')]: {
    padding: '0 16px',
    minWidth: 90,
    fontSize: '1rem',
    fontWeight: 700,
  },
}));
