export const REPLY_MAX_LENGTH = 1000;

/**
 * 게시판 댓글의 조회 옵션 - 페이징
 */
export const DEFAULT_REPLY_PAGING_OPTIONS = {
  pageNumber: 0, // 페이지번호 (0 => 1페이지)
  rowsPerPage: 100, // 한페이지에 표시할 게시물수
};

export interface ReplySearchOption {
  pageNumber: number; // 페이지번호 (0 => 1페이지)
  rowsPerPage: number; // 한페이지에 표시할 게시물수
}

const fixRepeatedEmptyLines = (str: string): string => {
  const EOL = str.match(/\r\n/gm) ? '\r\n' : '\n';
  const regExp = new RegExp(`(${EOL}){4,}`, 'gm');
  return str.replace(regExp, EOL + EOL + EOL);
};

export const fixBbsSubstance = (substance: string): string => {
  if (substance.length < 5) return substance;
  let str = substance.replace(/(.)(\1){5,}/gm, '$1$1$1');
  str = fixRepeatedEmptyLines(str);
  str = str.replace(/(\r?\n.)(\1){5,}/gm, '$1$1$1');
  str = str.replace(/(<p>&nbsp;<\/p>)\s*(\1){4,}/gim, '<p>&nbsp;</p><p>&nbsp;</p>');
  return str;
};

export const fixBbsSubstanceOrUndef = (substance?: string): string | undefined => {
  if (!substance) return substance;

  return fixBbsSubstance(substance);
};
