import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  IconButton,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from '@mui/material';
import { CodeProjectOrigin } from '@ocode/domain';
import { useLocale } from 'next-intl';
import { CustomDialog } from '../../components/CustomDialog';
import { CustomDialogTitle } from '@repo-shared-base/components/CustomDialogTitle';
import { routerPush } from '../../router';

export interface GallerySourceListDialogProps {
  sx?: SxProps;
  open: boolean;
  galleryTitle: string;
  onClose: () => void;
  projectOriginList?: CodeProjectOrigin[];
}

export function GallerySourceListDialog(props: GallerySourceListDialogProps) {
  const { open, onClose, projectOriginList, galleryTitle, sx } = props;
  const locale = useLocale();

  const handleClose = () => {
    onClose();
  };

  const handleClickPlayBtn = (project: CodeProjectOrigin) => () => {
    const { projectId } = project;
    // 임시 breed
    const breed = projectId.split('_')[0];
    routerPush(locale, '/project', breed, projectId);
  };

  const CustomTableRow = styled(TableRow)(() => ({
    '&:nth-of-type(even)': {
      backgroundColor: '#F3F5F8',
    },
    '& .MuiTableCell-root': {
      borderBottom: 'none',
    },
  }));
  return (
    <CustomDialog
      className="GallerySourceListDialog-root"
      open={open}
      maxWidth="sm"
      fullWidth
      onClose={handleClose}
      sx={sx}
    >
      <CustomDialogTitle
        onClose={handleClose}
        sx={{
          pl: {
            xs: 2,
            md: 4,
          },
        }}
      >
        <Box sx={{ py: 1.5, maxWidth: 300 }}>
          <Typography variant="caption" color="#76787A">
            프로젝트 출처
          </Typography>
          <Typography
            sx={{
              width: '100%',
              fontSize: '1.1rem',
              color: '#039BE5',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {galleryTitle}
          </Typography>
        </Box>
      </CustomDialogTitle>

      <DialogContent dividers sx={{ p: 0, minHeight: 330 }}>
        <TableContainer aria-label="sourceList table">
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  '& .MuiTableCell-root': {
                    fontWeight: 700,
                    py: 1,
                    '&:nth-of-type(1)': {
                      px: 0,
                    },
                    '&:last-child': {
                      width: 250,
                      px: 0,
                      ml: 2,
                    },
                  },
                }}
              >
                <TableCell align="center">순번</TableCell>
                <TableCell align="left">참조 프로젝트</TableCell>
                <TableCell align="center" sx={{ ml: 3 }}>
                  <Typography sx={{ ml: 4, fontWeight: 700 }}>게시일시</Typography>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody
              sx={{
                '& .MuiTableRow-root': {
                  border: 'none',
                },
              }}
            >
              {projectOriginList &&
                projectOriginList.map((project, idx) => (
                  <CustomTableRow>
                    <TableCell align="center" sx={{ fontWeight: 700 }}>
                      {idx + 1}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        maxWidth: 100,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        fontWeight: 700,
                      }}
                    >
                      {project.projectName}
                      <Typography
                        color="#76787A"
                        fontSize="0.75rem"
                        sx={{
                          maxWidth: 100,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {project.nickName}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        {project.deleted ? (
                          <Typography color="#76787A" fontSize="0.75rem" sx={{ mr: 2 }}>
                            삭제됨
                          </Typography>
                        ) : (
                          <IconButton sx={{ mr: 2 }} onClick={handleClickPlayBtn(project)}>
                            <PlayArrowIcon sx={{ color: '#039BE5' }} />
                          </IconButton>
                        )}
                        <Typography color="#76787A">2024.01.29</Typography>
                      </Box>
                    </TableCell>
                  </CustomTableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Typography fontSize="0.7rem" color="#76787A" px={3} py={2}>
          &#8251; Ocode 서비스의 저작권 관련 지침은 약관 및 저작권 이용정책을 반드시 확인 해 주세요.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>닫기</Button>
      </DialogActions>
    </CustomDialog>
  );
}
