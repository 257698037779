'use client';

import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import PersonIcon from '@mui/icons-material/Person';
import {
  type Breakpoint,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  DialogActions,
  DialogContent,
} from '@mui/material';
import { ProjectBreedKey } from '@ocode/domain';
import { useEffect, useState } from 'react';
import { BreedChips } from '../../components/BreedChips';
import { CustomDialog } from '../../components/CustomDialog';
import { CustomDialogTitle } from '@repo-shared-base/components/CustomDialogTitle';
import { BreedUtil } from '../../util/BreedUtil';
import { ProfileContentView } from './components/ProfileContentView/ProfileContentView';
import ProfileListView from './components/ProfileListView/ProfileListView';

export interface UserProfileViewDialogProps {
  accountId: number;
  open: boolean;
  onClose: () => void;
}

type PageId = 'profile' | 'gallery';

export function UserProfileViewDialog(props: UserProfileViewDialogProps) {
  const { accountId, open, onClose } = props;
  const [pageId, setPageId] = useState<PageId>('profile');
  const [maxWidth, setMaxWidth] = useState<Breakpoint>('xs');
  const [fullWidth, setFullWidth] = useState(false);
  const [breed, setBreed] = useState<ProjectBreedKey>();

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    if (pageId === 'profile') {
      setMaxWidth('xs');
      setFullWidth(false);
    }
    if (pageId === 'gallery') {
      setMaxWidth('sm');
      setFullWidth(true);
    }
  }, [pageId]);

  return (
    <CustomDialog
      open={open}
      onClose={handleClose}
      className="UserProfileViewDialog-root"
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      aria-labelledby="profile-view-dialog-title"
      aria-describedby="profile-view-dialog-description"
    >
      <CustomDialogTitle
        title="사용자 프로필"
        onClose={handleClose}
        sx={{ borderBottom: '1px solid #ddd' }}
      />
      <DialogContent sx={{ p: 0 }}>
        {pageId === 'profile' && <ProfileContentView accountId={accountId} />}
        {pageId === 'gallery' && (
          <Box>
            <BreedChips
              currentBreed={breed}
              breed={BreedUtil.allBreeds}
              undefinedLabel="전체"
              onChange={setBreed}
              sx={{
                my: 2,
              }}
            />
            <ProfileListView
              breed={breed ? [breed] : BreedUtil.allBreeds}
              accountId={accountId}
              onClose={handleClose}
              sx={{
                maxWidth: 'sm',
                width: '100%',
                minWidth: 280,
                minHeight: 300,
              }}
            />
          </Box>
        )}
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', py: 1, borderTop: '1px solid #ddd' }}>
        <BottomNavigation
          showLabels
          value={pageId}
          onChange={(_, newValue) => {
            setPageId(newValue as PageId);
          }}
        >
          <BottomNavigationAction label="프로필" value="profile" icon={<PersonIcon />} />
          <BottomNavigationAction label="작품" value="gallery" icon={<AutoStoriesIcon />} />
        </BottomNavigation>
      </DialogActions>
    </CustomDialog>
  );
}
