import {
  BlkBreeds,
  ProjectBreeds,
  TextBreeds,
  type BlkBreedKey,
  type ProjectBreedKey,
  type TextBreedKey,
} from '@ocode/domain';

export class BreedUtil {
  static readonly blkTypeBreeds = Object.keys(BlkBreeds) as BlkBreedKey[];

  static readonly textTypeBreeds = Object.keys(TextBreeds) as TextBreedKey[];

  static readonly allBreeds = Object.keys(ProjectBreeds) as ProjectBreedKey[];

  static getType(breed: ProjectBreedKey): 'blk' | 'text' | undefined {
    return this.isBlkType(breed) ? 'blk' : this.isTextType(breed) ? 'text' : undefined;
  }

  static isBlkType(breed: ProjectBreedKey): breed is BlkBreedKey {
    return breed in BlkBreeds;
  }

  static isTextType(breed: ProjectBreedKey): breed is TextBreedKey {
    return breed in TextBreeds;
  }

  static isValidBreed(breed: string): breed is ProjectBreedKey {
    return breed in ProjectBreeds;
  }
}
