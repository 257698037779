import type React from 'react';
import { useEffect, useState } from 'react';

interface Props {
  delayMs?: number;
  disabled?: boolean;
  children: React.ReactNode;
}

/**
 * 지정된 시간 후에 표시합니다.
 * EmptyView를 표시하기에 적당합니다.
 */
export function DelayMount(props: Props) {
  const { delayMs = 500, disabled, children } = props;
  const [timerFinished, setTimerFinished] = useState(false);

  useEffect(() => {
    if (disabled) return;
    if (delayMs <= 0) {
      setTimerFinished(true);
      return;
    }

    const timer = setTimeout(() => {
      setTimerFinished(true);
    }, delayMs);

    return () => {
      clearTimeout(timer);
    };
  }, [delayMs, disabled]);

  if (disabled) return null;

  return timerFinished ? children : null;
}
