import sanitizeHtml from 'sanitize-html';

const allowedTags = sanitizeHtml.defaults.allowedTags.concat(['img', 'figure', 'pre', 'code']);

const allowedAttributes = {
  '*': ['class', 'style', 'data-*', 'width', 'height'],
  a: ['href', 'name', 'target'],
  img: ['src', 'srcset', 'alt', 'title', 'width', 'height', 'loading'],
};

const allowedSchemes = ['data', 'http', 'https', 'ftp', 'mailto', 'tel', 'blob'];

export function sanitizeStrict(input: string | undefined | null) {
  if (!input) return '';
  return sanitizeHtml(input, {
    allowedTags,
    allowedAttributes,
    allowedSchemes,
  });
}

export function sanitize(input: string | undefined | null) {
  if (!input) return '';
  return sanitizeStrict(input);
}

export function sanitizeAllowEvery(input: string | undefined | null) {
  if (!input) return '';
  return sanitizeHtml(input, {
    allowedTags: false,
    allowedAttributes: false,
    allowedSchemes,
  });
}

export function removeExternalLinks(html: string | null | undefined): string | null | undefined {
  if (!html) return html;
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  const anchors = doc.querySelectorAll('a');
  for (let i = 0; i < anchors.length; i++) {
    const a = anchors[i];
    const span = document.createElement('span');
    span.innerHTML = a.innerHTML;
    a.parentNode?.replaceChild(span, a);
  }
  return doc.firstElementChild?.innerHTML;
}
