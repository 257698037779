import type { SxProps } from '@mui/material';
import type { Gallery } from '@ocode/domain';
import dynamic from 'next/dynamic';

type Props = {
  sx?: SxProps;
  className?: string;
  gallery: Gallery;
};

const BREED_PLAYERS: Record<string, React.ComponentType<Props>> = {
  js: dynamic(() => import('./preview-players/JsGalleryPreviewPlayer'), { ssr: false }),
  p5: dynamic(() => import('./preview-players/P5GalleryPreviewPlayer'), { ssr: false }),
  py: dynamic(() => import('./preview-players/PyGalleryPreviewPlayer'), { ssr: false }),
};

export function GalleryPreviewPlayer(props: Props) {
  const { gallery, ...restProps } = props;
  const PreviewPlayer = BREED_PLAYERS[gallery.breed as any];

  if (!PreviewPlayer) return null;

  return <PreviewPlayer gallery={gallery} {...restProps} />;
}
