import {
  FavoriteBorderOutlined as FavoriteBorderOutlinedIcon,
  Favorite as FavoriteIcon,
} from '@mui/icons-material';
import { type SxProps } from '@mui/material';
import clsx from 'clsx';
import { animate } from 'framer-motion';
import { useCallback, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useLoginUser } from '../store/useAuthStore';
import { handleError } from '../util/handle-error';
import { CountButtonWithIcon } from './count-with-icon/CountButtonWithIcon';

/**
 * 좋아요 추가/제거 API 함수 타입
 * 좋아요 추가/제거 API는 galleryId를 파라미터로 받고, 좋아요 건수를 리턴합니다.
 */
type ApiFn = (params: { galleryId: number }) => Promise<{ value: number }>;

interface Props {
  sx?: SxProps;

  className?: string;

  size?: 'small' | 'medium' | 'large';

  /**
   * 갤러리 ID
   * API 호출시 사용
   */
  galleryId: number;

  /**
   * 현재의 좋아요 개수
   */
  count: number | string;

  /**
   * 텍스트 색상
   */
  textColor?: string;

  /**
   * 아이콘 색상
   */
  iconColor?: string;

  /**
   * active일때, 텍스트 색상
   */
  activeTextColor?: string;

  /**
   * active일때, 아이콘 색상
   */
  activeIconColor?: string;

  /**
   * 현재 좋아요 여부
   */
  active?: boolean;

  /**
   * 좋아요 추가 API
   * ex) api.gallery.favorAdd
   */
  addApiFn: ApiFn;

  /**
   * 좋아요 제거 API
   * ex) api.gallery.favorRemove
   */
  removeApiFn: ApiFn;

  /**
   * 좋아요 변경 콜백
   */
  onChangeValue?: (galleryId: number, count: number, isActive: boolean) => void;
}

/**
 * 숫자, 버튼, 하트 아이콘
 */
export function GalleryFavorButton(props: Props) {
  const {
    galleryId,
    className,
    active = false,
    textColor = '#666',
    iconColor = '#888',
    activeTextColor,
    activeIconColor = '#f00',
    onChangeValue,
    ...restProps
  } = props;
  const [loading, setLoading] = useState(false);
  const { isLoggedIn } = useLoginUser();
  const ref = useRef<HTMLDivElement | null>(null);
  const addApiFnRef = useRef(props.addApiFn);
  addApiFnRef.current = props.addApiFn;

  const removeApiFnRef = useRef(props.removeApiFn);
  removeApiFnRef.current = props.removeApiFn;

  const doFavorAdd = useCallback(async (galleryId: number): Promise<number | null> => {
    setLoading(true);
    try {
      const { value } = await addApiFnRef.current({ galleryId });
      return value;
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
    return null;
  }, []);

  const doFavorRemove = useCallback(async (galleryId: number): Promise<number | null> => {
    setLoading(true);
    try {
      const { value } = await removeApiFnRef.current({ galleryId });
      return value;
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
    return null;
  }, []);

  // 좋아요를 누른 후에 애니메이션
  const runAnim = () => {
    const elem = ref.current;
    if (elem) {
      animate([
        [elem, { scale: 0.8 }, { duration: 0.15 }],
        [elem, { scale: 1.1 }, { duration: 0.15 }],
        [elem, { scale: 0.9 }, { duration: 0.15 }],
        [elem, { scale: 1.0 }, { duration: 0.15 }],
      ]);
    }
  };

  const handleClick = () => {
    if (!isLoggedIn) {
      toast.warn('로그인이 필요합니다');
      return;
    }
    if (loading) return;

    if (active) {
      doFavorRemove(galleryId).then((count) => {
        if (typeof count === 'number') {
          onChangeValue?.(galleryId, count, false);
          runAnim();
        }
      });
    } else {
      doFavorAdd(galleryId).then((count) => {
        if (typeof count === 'number') {
          onChangeValue?.(galleryId, count, true);
          runAnim();
        }
      });
    }
  };

  const activeTextColor2 = activeTextColor ?? textColor;
  const activeIconColor2 = activeIconColor ?? iconColor;

  return (
    <CountButtonWithIcon
      className={clsx('GalleryFavorButton-root', className)}
      disabled={loading}
      onLoadRef={(elem) => {
        ref.current = elem;
      }}
      icon={active ? <FavoriteIcon /> : <FavoriteBorderOutlinedIcon />}
      iconColor={active ? activeIconColor2 : iconColor}
      textColor={active ? activeTextColor2 : textColor}
      onClick={handleClick}
      {...restProps}
    />
  );
}
